import React, {lazy} from "react";
import "./Layout.scss";
import {useAppSelector} from "@App/hooks/hooks";
import AppRoutes from "@Src/routes/route";

const HeaderIndex = lazy(() =>
    import('@Features/header')
        .then(({HeaderIndex}) => ({default: HeaderIndex})),
);
const CustomSidebar = lazy(() =>
    import('@Features/sidebar/sidebar')
        .then(({CustomSidebar}) => ({default: CustomSidebar})),
);
const Footer = lazy(() =>
    import('@Src/features/footer/footer')
        .then(({Footer}) => ({default: Footer})),
);


const Layout = () => {

     return (
        <>
            {


                    <div className="layout">
                        <React.Suspense>
                            <HeaderIndex/>
                        </React.Suspense>
                        <div className="layout-container">
                            {/*<React.Suspense>*/}

                            {/*     <CustomSidebar/>*/}

                            {/*</React.Suspense>*/}
                                 <AppRoutes/>
                                <React.Suspense>
                                     <Footer/>

                                </React.Suspense>
                            </div>
                        </div>
            }
        </>
    );
}

export default Layout;
