import React from 'react'
import { Routes, Route } from 'react-router-dom';
import './App.scss';
import { useAppDispatch, useAppSelector } from '@App/hooks/hooks';
import { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '@Components/layout/Layout';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';


const App: React.FC = () => {
	const dispatch = useAppDispatch();


	return (
		<div>
			<Routes>
				<Route path='/*' element={<Layout/>}/>
			</Routes>
			<div>

				<div>
					<Toaster
						position="top-center"
						reverseOrder={false}
					/>
				</div>
			</div>
		</div >
	);
}

export default App;
