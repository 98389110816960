import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {HeaderTypes} from "@Src/types/api-response-interface";
import {Currency} from "@Constants/constants";

const initialState = {
    loading: false,
    user: null,
    loginError: "",

};

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        showSpinner: (state, action: any) => {
            state.loading = true;
        },
    }
});

export const { showSpinner } = globalSlice.actions;
export default globalSlice.reducer;
