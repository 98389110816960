import React, {lazy} from 'react'
import {Route, Routes} from "react-router-dom";
import {AppRouteURL} from "@Src/routes/app-route-urls";
import Loader from "@Components/loader/Loader";
import 'primeicons/primeicons.css';



const Home  = lazy(() =>
    import('@Pages/home/Home')
        .then(({ Home  }) => ({ default:Home   })),
);

export default function AppRoutes() {

    return (
        <>
            <Routes>
                <Route
                    path={AppRouteURL.HOME}
                    element={
                        <React.Suspense fallback={<Loader/>}>
                            <Home/>
                        </React.Suspense>
                    }
                />

                </Routes>
        </>

    )
}